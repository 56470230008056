import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

import resolver from "./LoginForm.schema";

const LoginForm = ({ onSubmit, isWorking }) => {
  const { register, handleSubmit, formState } = useForm({ resolver });
  const isDisabled = isWorking || formState.isSubmitting;

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        {...register("email")}
        error={!!formState.errors?.email?.message}
        helperText={formState.errors?.email?.message}
        disabled={isDisabled}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        {...register("password")}
        error={!!formState.errors?.password?.message}
        helperText={formState.errors?.password?.message}
        disabled={isDisabled}
      />
      <LoadingButton
        loading={isDisabled}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </LoadingButton>
    </Box>
  );
};

export default LoginForm;
