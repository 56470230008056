import { Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoginContainer = styled(Container)(
  ({ theme }) => `
  padding: ${theme.spacing(4)};
  margin-top: ${theme.spacing(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
`
);
LoginContainer.defaultProps = { maxWidth: "xs", component: Paper };

export default LoginContainer;
